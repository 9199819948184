<template>
  <v-container>
    <v-tabs v-model="tab">
      <v-tab>ChargeAmps</v-tab>
      <v-tab>Chirpstack</v-tab>

      <v-tab-item :key="integrationTypes.CHARGE_AMPS">
        <v-container
          style="margin: 0; padding: 0; width: 100%; max-width: 100%"
          v-if="!loading"
        >
          <integration-charge-amps
            :integrations="chargeAmps"
            @update="getIntegrations"
          />
        </v-container>
      </v-tab-item>
      <v-tab-item :key="integrationTypes.CHIRPSTACK">
        <v-container
          style="margin: 0; padding: 0; width: 100%; max-width: 100%"
          v-if="!loading"
        >
          <integration-chirp-stack
            :integrations="chirpStacks"
            @update="getIntegrations"
          />
        </v-container>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import SettingsRepository from "@/api/repositories/settingRepository";
import IntegrationChargeAmps from "./IntegrationChargeAmps.vue";
import IntegrationChirpStack from "./IntegrationChirpStack.vue";
import { IntegrationTypes } from "@/_helpers/CsharpEnum";

export default {
  name: "IntegrationSettings",

  components: {
    IntegrationChargeAmps,
    IntegrationChirpStack,
  },

  data() {
    return {
      loading: false,
      tab: IntegrationTypes.CHARGE_AMPS,
      chargeAmps: [],
      chirpStacks: [],
    };
  },

  async created() {
    await this.getIntegrations();
  },

  computed: {
    integrationTypes: () => IntegrationTypes,
  },

  methods: {
    async getIntegrations() {
      this.loading = true;
      try {
        let integrations = (await SettingsRepository.getIntegrationSettings())
          .data;
        this.chargeAmps = integrations.filter(
          (integration) => integration.type === IntegrationTypes.CHARGE_AMPS
        );
        this.chirpStacks = integrations.filter(
          (integrations) => integrations.type === IntegrationTypes.CHIRPSTACK
        );
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
  },
};
</script>
