<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <p>{{ $t("companysetting.field.mapSettings.intervalSlider") }}</p>
        <v-slider
          step="1"
          thumb-label
          ticks
          prepend-icon="mdi-alarm"
          validate-on-blur
          :max="maxSliderValue"
          :min="0"
        >
          <template v-slot:thumb-label="{ value }">
            {{
              mapSettings.slidervalues[value]
                ? mapSettings.slidervalues[value] + "h"
                : "N/A"
            }}
          </template>
        </v-slider>

        <v-text-field
          :label="$t('companysetting.field.mapSettings.sliderInputLabel')"
          :hint="$t('companysetting.field.mapSettings.sliderInputHint')"
          autocomplete="off"
          type="text"
          v-model="sliderTextInput"
          prepend-icon="mdi-alarm"
          @input="updateSlider"
          :rules="[rules.sliderTextInput]"
          ref="sliderInput"
        />
      </v-col>
      <v-col cols="8" v-if="mapSettings?.displayAttributes?.length > 0">
        <v-data-table
          :headers="headers"
          :items="mapSettings.displayAttributes"
          :items-per-page="10"
          disable-pagination
          hide-default-footer
          dense
        >
          <template v-slot:[`item.nodeType`]="{ item }">
            <v-chip outlined :color="getNodeTypeColor(item.nodeType)">
              {{ $t("tag.nodeTypes." + item.nodeType) }}
            </v-chip>
          </template>

          <template v-slot:[`item.display`]="{ item }">
            <v-checkbox
              v-model="mapSettings.showTypes"
              multiple
              :label="$t('common.show')"
              :value="item.nodeType"
              @change="updateShow"
              dense
            />
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <v-select
              v-model="item.attribute"
              :items="selectableAttributes"
              :item-value="(item) => item"
              disable-lookup
              dense
              clearable
              @change="update"
            >
              <template v-slot:item="data">
                <v-list-item-content class="pa-2">
                  <v-list-item-title v-html="data.item.name" />
                </v-list-item-content>
              </template>

              <template v-slot:selection="data">
                <v-chip small>{{ data.item.name }}</v-chip>
              </template>
            </v-select>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex/dist/vuex.common.js";
import CsharpEnum from "../../../_helpers/CsharpEnum";
import { FEATURES } from "../../../_helpers/RestrictHelper";
import { getDefaultMapSettingSlidervalues } from "../../../_helpers/companySettings";

export default {
  name: "mapSettingsForm",

  data() {
    return {
      mapSettings: {
        displayAttributes: [],
        slidervalues: [],
        showTypes: [],
      },
      selectableAttributes: [],
      headers: [
        {
          text: this.$t("tag.fields.nodeType"),
          value: "nodeType",
          sortable: false,
        },
        {
          text: this.$t("companysetting.field.mapSettings.display"),
          value: "display",
          sortable: false,
        },
        {
          text: this.$t("companysetting.field.mapSettings.attributeName"),
          value: "name",
          sortable: false,
        },
      ],
      sliderTextInput: "",
      rules: {
        sliderTextInput: (v) => !v || /^[,0-9]+$/.test(v),
      },
    };
  },

  props: {
    stringInput: {
      type: String,
      default: () => "",
    },
  },

  computed: {
    ...mapState("tag", ["tagsAttributes"]),

    maxSliderValue() {
      let size = this.mapSettings?.slidervalues?.length;
      return size > 0 ? size - 1 : 0;
    },
  },

  methods: {
    ...mapActions("tag", ["getAllTagAttributes"]),

    getNodeTypeColor(nodeType) {
      switch (nodeType) {
        case CsharpEnum.NodeTypes.API:
          return "orange";
        case CsharpEnum.NodeTypes.MQTT:
          return "green";
        case CsharpEnum.NodeTypes.NODERED:
          return "red";
        case CsharpEnum.NodeTypes.SPARKPLUG:
          return "teal";
        default:
          return "primary";
      }
    },

    updateSlider() {
      if (this.$refs.sliderInput.validate()) {
        this.mapSettings.slidervalues = this.sliderTextInput
          .split(",")
          .filter((x) => x)
          .map((x) => Number(x));
        if (this.mapSettings.slidervalues.length == 0) {
          this.mapSettings.slidervalues = getDefaultMapSettingSlidervalues;
        }
        this.update();
      }
    },

    updateShow() {
      this.mapSettings.showTypes.sort();
      this.update();
    },

    update() {
      this.$emit("update", JSON.stringify(this.mapSettings));
    },

    async setupTable() {
      let nodeTypes = CsharpEnum.NodeTypes;
      if (!this.canAccessFeature(FEATURES.OPC)) delete nodeTypes.OPC;

      Object.values(nodeTypes).forEach((x) => {
        if (this.mapSettings.displayAttributes.find((y) => y.nodeType == x))
          return;

        this.mapSettings.displayAttributes.push({
          nodeType: x,
          attribute: null,
        });
      });
      this.mapSettings.displayAttributes.sort(
        (a, b) => a.nodeType < b.nodeType
      );
    },
  },

  async created() {
    await this.getAllTagAttributes();
    this.mapSettings = JSON.parse(this.stringInput);

    var res = [];
    for (var o of this.tagsAttributes) {
      if (res.some((x) => x.normalizedName === o.normalizedName)) continue;

      res.push({ name: o.name, normalizedName: o.normalizedName });
    }
    this.selectableAttributes = res;

    this.sliderTextInput = this.mapSettings.slidervalues;
    await this.setupTable();
  },

  watch: {
    nodeHeaders: {
      handler(value) {
        this.mapSettings = JSON.parse(value);
      },
      deep: true,
    },
  },
};
</script>
