<template>
  <v-container>
    <v-form
      ref="chirpStackForm"
      v-model="valid"
      lazy-validation
      @submit.prevent="handleSubmit"
    >
      <v-text-field
        autocomplete="off"
        v-model="payload.integrationName"
        :label="$t('integrations.chirpStack.fields.name')"
        :rules="rules"
      />

      <broker-selector v-model="brokerString" includeHostname />

      <v-radio-group v-model="payload.version" @change="setTopics">
        <h5 class="text-left">
          {{ $t("integrations.chirpStack.fields.version") }}
        </h5>
        <v-radio
          v-for="n in Object.values(versions)"
          :key="n"
          :label="$t('integrations.chirpStack.versions.' + n)"
          :value="n"
        />
      </v-radio-group>

      <v-text-field
        autocomplete="off"
        v-model="payload.topics.gateway"
        :label="$t('integrations.chirpStack.fields.topics.gateway')"
        :hint="$t('integrations.chirpStack.gatewayTopicHint')"
      />

      <v-text-field
        autocomplete="off"
        v-model="payload.topics.device"
        :label="$t('integrations.chirpStack.fields.topics.device')"
        :hint="$t('integrations.chirpStack.deviceTopicHint')"
      />

      <v-btn block color="primary" type="submit">
        {{ $t("common.add") }}
      </v-btn>
    </v-form>

    <v-data-table
      :headers="headers"
      :items="chirpStack"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.version="{ item }">
        {{ $t("integrations.chirpStack.versions." + item.version) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          @click="handleRemove(item.integrationSettingId)"
          class="mr-2"
          color="red"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import SettingsRepository from "@/api/repositories/settingRepository";
import {
  IntegrationTypes,
  IntegrationValueTypes,
  ChirpStackVersions,
} from "@/_helpers/CsharpEnum";
import { CHRIPSTACK_DEFAULT_TOPICS } from "../../../_helpers/integrationHelper";
import BrokerSelector from "../../mqtt/BrokerSelector.vue";

const integrationType = IntegrationTypes.CHIRPSTACK;
export default {
  name: "IntegrationChirpStack",
  props: {
    integrations: {
      type: Array,
      default: () => [],
    },
  },

  emits: ["update"],

  components: {
    BrokerSelector,
  },

  data() {
    return {
      valid: false,
      rules: [(v) => !!v || this.$t("validation.requiredSingle")],
      localIntegrations: [],
      brokerString: "",
      payload: {
        integrationName: "",
        broker: {
          brokerId: "",
          hostname: "",
        },
        version: 0,
        topics: {
          gateway: "",
          device: "",
        },
      },
      headers: [
        {
          text: this.$t("integrations.chirpStack.fields.name"),
          value: "integrationName",
        },
        {
          text: this.$t("integrations.chirpStack.fields.brokerHostname"),
          value: "hostname",
        },
        {
          text: this.$t("integrations.chirpStack.fields.version"),
          value: "version",
        },
        {
          text: this.$t("integrations.chirpStack.fields.topics.gateway"),
          value: "gateway",
          sortable: false,
        },
        {
          text: this.$t("integrations.chirpStack.fields.topics.device"),
          value: "device",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },

  computed: {
    chirpStack() {
      return this.localIntegrations
        .filter((integration) => integration.type === integrationType)
        .map((integration) => {
          let json = JSON.parse(integration.value);
          return {
            integrationSettingId: integration.integrationSettingId,
            integrationName: json.integrationName,
            hostname: json.broker.hostname,
            version: json.version,
            gateway: json.topics.gateway,
            device: json.topics.device,
          };
        });
    },
    versions: () => ChirpStackVersions,
  },

  methods: {
    setTopics(version) {
      this.payload.topics.gateway =
        CHRIPSTACK_DEFAULT_TOPICS[version]?.gateway ?? "";
      this.payload.topics.device =
        CHRIPSTACK_DEFAULT_TOPICS[version]?.device ?? "";
    },

    async handleRemove(integrationSettingId) {
      try {
        await SettingsRepository.removeIntegrationSetting(integrationSettingId);
      } catch (error) {
        console.error(error);
      }

      this.$emit("update");
    },

    async handleSubmit() {
      if (
        !this.$refs.chirpStackForm.validate() ||
        this.payload.broker.brokerId == ""
      )
        return;

      // Construct payload
      let setting = {
        type: integrationType,
        valueType: IntegrationValueTypes.JSON,
        value: JSON.stringify(this.payload),
        key: "CHIRPSTACK",
      };

      try {
        await SettingsRepository.createIntegrationSetting(setting);
      } catch (error) {
        console.error(error);
      }

      this.$emit("update");
    },
  },

  created() {
    this.localIntegrations = this.integrations;
    this.setTopics(this.payload.version);
  },

  watch: {
    integrations: {
      handler(value) {
        this.localIntegrations = value;
      },
      deep: true,
    },

    brokerString: {
      handler(value) {
        let temp = value.split(":");
        this.payload.broker.brokerId = temp[0] ?? "";
        this.payload.broker.hostname = temp[1] ?? "";
      },
    },
  },
};
</script>
