<template>
  <v-card flat>
    <v-card-title>
      {{ $t("mqtt.brokerListTitle") }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        label="Search"
        single-line
      ></v-text-field>
    </v-card-title>

    <v-card-text>
      <v-data-table :headers="headers" :items="brokersWithMetrics" dense>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            @click="openDialog(item)"
            icon
            outlined
            small
            v-if="allowedToEdit(item)"
            ><v-icon color="primary">mdi-pen</v-icon></v-btn
          >
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="item.status ? 'green' : 'red'" small outlined>{{
            item.status ? "Connected" : "Not Connected"
          }}</v-chip>
        </template>
      </v-data-table>
    </v-card-text>
    <create-broker-dialog
      ref="brokerDialog"
      v-model="currentBroker"
      :edit="true"
      v-on:update-list="reload"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CreateBrokerDialog from "./CreateBrokerDialog.vue";
export default {
  components: { CreateBrokerDialog },
  data() {
    return {
      search: "",
      currentBroker: {
        mqttBrokerId: null,
        hostname: "",
        port: 1883,
        sslMode: 0,
        version: 0,
        CACertificate: "",
        ClientCertificate: "",
        ClientKey: "",
        KeyPassPhrase: "",
        useAuth: false,
        username: "",
        password: "",
        isGlobal: false,
      },
      headers: [
        { text: this.$t("mqtt.fields.status"), value: "status" },
        { text: this.$t("mqtt.fields.hostname"), value: "hostname" },
        { text: this.$t("mqtt.fields.port"), value: "port" },
        { text: this.$t("mqtt.fields.isGlobal"), value: "isGlobal" },
        {
          text: this.$t("mqtt.fields.metrics.messages.total"),
          value: "metrics.messagesTotal",
        },
        {
          text: this.$t("mqtt.fields.metrics.messages.week"),
          value: "metrics.messagesWeek",
        },
        {
          text: this.$t("mqtt.fields.metrics.messages.day"),
          value: "metrics.messagesDay",
        },
        {
          text: this.$t("mqtt.fields.metrics.publish.total"),
          value: "metrics.publishTotal",
        },
        { text: "", value: "actions" },
      ],
    };
  },

  computed: {
    ...mapGetters("mqtt", ["brokersWithMetrics"]),
  },

  methods: {
    ...mapActions("mqtt", ["getBrokersWithMetrics"]),

    openDialog(item) {
      this.currentBroker = item;
      this.$refs.brokerDialog.openDialog();
    },

    allowedToEdit(item) {
      if (this.permitted("SuperAdmin")) return true;

      if (item.isGlobal) return false;

      return true;
    },

    async reload() {
      await this.getBrokersWithMetrics();
    },
  },

  async created() {
    await this.getBrokersWithMetrics();
  },
};
</script>
