export const CHRIPSTACK_DEFAULT_TOPICS = {
  //Versions from CsharpEnum ChirpStackVersions
  0: {
    gateway: "gateway/+/event/+",
    device: "application/+/device/+/event/+",
  },
  1: {
    gateway: "eu868/gateway/+/event/+",
    device: "application/+/device/+/event/+",
  },
};
